import { useContext, useMemo, useCallback } from 'react'
import { I18nContext } from '@sholdi/providers/I18n'

/**
 * Custom hook for translation.
 *
 * @returns {UseTranslationReturn} An object containing locale information and a translation function.
 * @throws Will throw an error if used outside an I18nProvider.
 */
export default function useTranslation() {
  const context = useContext(I18nContext)

  if (!context || !context.t) {
    throw new Error('useTranslation must be used within an I18nProvider!')
  }

  const { activeLocale, locale, t } = context

  /**
   * Translates a given key with optional parameters.
   *
   * @param {string} key - The translation key.
   * @param {Object} [params={}] - Parameters for interpolation.
   * @returns {string} The translated string with parameters interpolated.
   */
  const translate = useCallback(
    (key, params = {}) => {
      const translation = t(key, params)

      // Early return if no placeholders or no params
      if (!translation.includes('{') || Object.keys(params).length === 0) {
        return translation
      }

      // Split translation and replace placeholders
      const parts = translation.split(/(\{\w+})/g)
      return parts.map(part => {
        const match = part.match(/\{(\w+)}/)
        if (match && params[match[1]]) {
          return params[match[1]] // could be string, number, or React component
        }
        return part
      })
    },
    [t],
  )

  // Memoize the returned object to prevent unnecessary re-renders
  return useMemo(
    () => ({
      activeLocale,
      locale,
      t: translate,
    }),
    [activeLocale, locale, translate],
  )
}
